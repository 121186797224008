<template>
  <div class="'overflow-hidden'" style="border-radius: 12px">
    <div v-if="isEditingLayout && canEditLayout" class="p-50 m-50 zindex-4 settings">
      <!-- Delete Element -->
      <feather-icon
        icon="Trash2Icon"
        size="20"
        role="button"
        class="text-primary pointer"
        @click.stop.prevent="isUnshareModalVisible = true"
      />
      <!-- Edit Element -->
      <feather-icon
        icon="Edit2Icon"
        size="20"
        class="text-primary ml-1 pointer"
        role="button"
        @click="openModal"
      />
    </div>

    <!-- Type Text -->
    <div 
      v-if="element.type === 'text'" 
      class="m-2 border-radius text-element" 
      v-html="translate(element.customizations.text)" 
    />

    <!-- Type Image -->
    <div
      v-if="element.type === 'image'"
      class="p-0 border-radius d-flex align-items-center justify-content-center"
    >
      <b-link
        v-if="!isEditingLayout"
        :active="!!element.customizations.imageURLButton"
        :href="element.customizations.imageURLButton?element.customizations.imageURLButton:''"
        target="_blank"
        :style="element.customizations.imageURLButton?'':'cursor:default'"
      >
        <b-img
          v-if="element.customizations.isLink"
          :src="element.customizations.linkURL || element.content || placeholder"
          :alt="element.key"
          fluid-grow
        />
        <b-img
          v-else
          :src="element.content || placeholder"
          :alt="element.key"
          fluid-grow
          @error="setAltImg"
        />
      </b-link>

      <b-img
        v-else
        :src="element.content || placeholder"
        :alt="element.key"
        fluid-grow
        :style="'cursor:move'"
        @error="setAltImg"
      />
    </div>

    <!-- Type Iframe -->
    <div
      v-if="element.type === 'iframe'"
      class="p-0 w-100 h-100 border-radius"
    >
      <iframe
        :src="element.customizations.linkURL"
        class="h-100 w-100 border-0"
        :style="isEditingLayout?
          'pointer-events:none':''"
      />
    </div>

    <!-- Type Video -->
    <div
      v-if="element.type === 'video'"
      class="border-radius"
    >
      <b-embed
        v-if="element.customizations.isLink"
        :type="element.customizations.isLink?'iframe':'video'"
        controls
        :src="srcVideo"
        aspect="16by9"
        allowfullscreen
        :style="element.customizations.isLink && isEditingLayout?
          'pointer-events:none':''"
      />
      <b-embed
        v-else
        controls
        aspect="16by9"
        allowfullscreen
        type="video"
      >
        <source :src="element.content">
      </b-embed>
    </div>

    <!-- Type Separator -->
    <div v-if="element.type === 'separator'">
      <!-- Type Horizontal -->
      <div v-if="element.customizations.separator==='horizontal'" class="mt-1 horizontal-separator">
        <p v-if="element.customizations.separatorTitle" class="h4 ml-2 text-primary">
          {{ translate(element.customizations.separatorTitle) }}
        </p>
        <div class="line line__horizontal" />
      </div>
      <!-- Type Vertical -->
      <div v-if="element.customizations.separator==='vertical' && !isGridDisabled" class="line line__vertical" />
    </div>

    <!-- Type Call to Action call-to-action (CTA) image in BG -->
    <div
      v-if="element.type === 'cta'"
      class="p-2 d-flex flex-column h-100 w-100 justify-content-center align-items-center border-radius"
      :style="element.content ? `background-image:url(${element.content}); background-size: ${getImageSize(element.customizations.imageSize)}; background-position: center; background-repeat: no-repeat;`:''"
    >
      <!--       <div
        v-if="!element.content"
        :class="isGridDisabled?'':'position-absolute'"
        class="w-100 h-100"
        style="background-color:rgba(255,255,255,0.6)"
      /> -->
      <h4 v-if="element.customizations.ctaTitle" class="zindex-1">
        {{ translate(element.customizations.ctaTitle) }}
      </h4>
      <p v-if="element.customizations.ctaHeadline" class="zindex-1">
        {{ translate(element.customizations.ctaHeadline) }}
      </p>
      <b-button
        v-if="element.customizations.ctaButton && element.customizations.ctaButtonLink"
        :target="getTarget(element.customizations.ctaButtonLink)"
        variant="primary"
        class="zindex-1"
        :href="element.customizations.ctaButtonLink"
      >
        {{ translate(element.customizations.ctaButton) }}
      </b-button>
    </div>

    <!-- Type Call to Action (CTA 2) image under title -->
    <div
      v-if="element.type === 'cta2'"
      class="p-3 d-flex flex-column h-100 w-100 justify-content-center align-items-center border-radius"
    >
      <h4 v-if="element.customizations.ctaTitle" class="zindex-1">
        {{ translate(element.customizations.ctaTitle) }}
      </h4>
      <!-- <img 
        :src="element.content" 
        class="cta-img"
      /> -->
      <div 
        :style="element.content ? `background-image:url(${element.content}); background-size: ${getImageSize(element.customizations.imageSize)}; background-position: center; background-repeat: no-repeat; width:100%; height: 100%; min-height: 100px;`:''"
        class="cta-img"
      >
        &nbsp;
      </div>
      <p v-if="element.customizations.ctaHeadline" class="zindex-1">
        {{ translate(element.customizations.ctaHeadline) }}
      </p>
      <b-button
        v-if="element.customizations.ctaButton && element.customizations.ctaButtonLink"
        :target="getTarget(element.customizations.ctaButtonLink)"
        variant="primary"
        class="zindex-1"
        :href="element.customizations.ctaButtonLink"
      >
        {{ translate(element.customizations.ctaButton) }}
      </b-button>
    </div>

    <!-- Delete Element Modal -->
    <unshare-modal
      v-model="isUnshareModalVisible"
      model-type="element"
      @remove-item="handleRemoveItem"
    />

    <!-- Create Element Modal -->
    <modal-create-element
      v-model="isManageElementModalVisible" 
      :item-to="element" 
      @edited="setEditedItem" 
    />
  </div>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import EventsPlaceholder from '@/assets/images/placeholders/light/events.svg';
import GridStatusMixin from '@core/mixins/apps-layout/GridStatusMixin';
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import ModalCreateElement from '@/@core/components/layouts/apps-layout/elements-layout/ModalCreateElement.vue';

export default {
  name: 'ElementsListWidget',
  components: { UnshareModal, ModalCreateElement },
  mixins: [GridStatusMixin, ToastNotificationsMixin],

  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isUnshareModalVisible: false,
      isManageElementModalVisible: false,
    };
  },
  async created() {
    // console.log('this.element', this.element);
  },
  computed: {
    placeholder() {
      return EventsPlaceholder;
    },
    isHome() {
      return this.$route.name === 'Resumen';
    },
    canEditLayout() {
      return this.isHome && !this.isGridDisabled;
    },
    isEditingLayout() {
      return this.$store.getters[ENABLED_APPS_GETTERS.isEditingLayout];
    },
    srcVideo() {
      return this.element.customizations.linkURL.replace('watch?v=', 'embed/');
    }
  },
  methods: {
    setEditedItem(response) {
      this.element.content = response.content;
      this.element.customizations = response.customizations;
    },
    setAltImg(event) {
      setTimeout(() => {
        event.target.src = this.element.content;
      }, 1000);
    },
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    openModal() {
      this.isManageElementModalVisible = true;
      // this.$bvModal.show('modal-create-element');
    },

    async handleRemoveItem(response) {
      if (response === true) {
        try {
          await this.$store.dispatch('createItem', {
            item: {
              itemType: '/elements/delete',
              customName: 'elements',
              requestConfig: {
                key: this.element.key,
              },
            },
          });
          this.notifySuccess(this.$t('layouts.message.saved-success'));
        } catch {
          this.notifyError(this.$t('error-message.general-error'));
        }
      }
    },
    getTarget(link) {
      // console.log('link', link);
      const isInternalLink = link.includes('.nectios.com') || link.includes('.nectios-staging.com') || link.includes('http://localhost:8080/');
      // console.log('isInternalLink', isInternalLink);
      return isInternalLink ? '' : '_blank';
    },
    getImageSize(imageSize){
      return imageSize ?? 'cover';
    }
  },

};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include";
.line{
  background-color:$primary;
  &__vertical{
    width:3px;
    height:100vh;
    margin:auto
  }
  &__horizontal{
    height:3px;
  }
}
.imatge {
   filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1));
   white-space: normal;
   margin-top: .5rem;
   margin-bottom: 1.5rem;
   background-color: #fff;
   border-radius: 12px;
   padding: 18px;
   overflow: hidden;
   vertical-align: top;
}
.settings{
  position:fixed;
  right:15px;
  background-color:lighten($primary, 50%);
  border-radius: 12px
}
.text-element::v-deep img {
  max-width: 100% !important;
  height: auto !important;
}
.border-radius{
    border-radius: 12px;
    overflow: hidden;
}

.horizontal-separator {
  position: absolute;
  bottom: 15px;
  width: 100%;
}
@media (max-width: 767px) {
  .horizontal-separator {
    position: unset;
  }
}

.cta-img {
  max-height: calc(100% - 80px); 
  border-radius: 1rem; 
  margin-bottom: 1rem;
}
</style>
